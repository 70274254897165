<script>
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'MarketplaceCategories',

  props: {
    isOutside: {
      type: Boolean,
      default: false
    },

    oneLine: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState('marketplace', ['marketplaceTabId']),
    ...mapGetters('user', ['isAdmin']),

    cards () {
      return [
        {
          id: 'game',
          title: this.$t('servicesInGame'),
          icon: 'https://cdn.gaming-goods.com/images/marketplace/groups/games.svg'
        },
        {
          id: 'mobile_game',
          title: this.$t('servicesInMGame'),
          icon: 'https://cdn.gaming-goods.com/images/marketplace/groups/mobile-games.svg'
        },
        {
          id: 'comics',
          title: this.$t('comics'),
          url: '/marketplace/comics',
          icon: 'https://cdn.gaming-goods.com/images/marketplace/groups/comics.svg',
          // adminOnly: true,
          ruOnly: true
        },
        {
          id: 'ps',
          title: 'PS',
          url: '/marketplace/playstation',
          icon: 'https://cdn.gaming-goods.com/images/marketplace/groups/ps.svg'
        },
        {
          id: 'xbox',
          title: 'Xbox',
          url: '/marketplace/xbox',
          icon: 'https://cdn.gaming-goods.com/images/marketplace/groups/xbox.svg'
        },
        {
          id: 'nintendo',
          title: 'Nintendo',
          url: '/marketplace/nintendo',
          icon: 'https://cdn.gaming-goods.com/images/marketplace/groups/nintendo.svg'
        },
        {
          id: 'steam',
          title: 'Steam',
          url: '/marketplace/steam',
          icon: 'https://cdn.gaming-goods.com/images/marketplace/groups/steam.svg'
        },
        {
          id: 'appstore',
          title: 'App Store',
          url: '/marketplace/app-store-and-itunes',
          icon: 'https://cdn.gaming-goods.com/images/marketplace/groups/appstore.svg'
        },
        {
          id: 'app',
          title: this.$t('applications'),
          icon: 'https://cdn.gaming-goods.com/images/marketplace/groups/applications.svg'
        },
        {
          id: 'social',
          title: this.$t('socialNetworks'),
          icon: 'https://cdn.gaming-goods.com/images/marketplace/groups/socials.svg'
        }
        // {
        //   id: 'software',
        //   title: this.$t('software'),
        //   icon: 'https://cdn.gaming-goods.com/images/marketplace/groups/software.svg'
        // },
        // {
        //   id: 'offerwall',
        //   title: this.$t('offerwalls'),
        //   icon: 'https://cdn.gaming-goods.com/images/marketplace/groups/offer-wall.svg'
        // },
        // {
        //   id: 'others',
        //   title: this.$t('others'),
        //   icon: 'https://cdn.gaming-goods.com/images/marketplace/groups/other.svg'
        // }
      ].filter(item => !item.adminOnly || this.isAdmin)
        .filter(item => !item.ruOnly || this.$i18n.locale === 'ru')
    }
  },

  methods: {
    ...mapMutations('marketplace', ['setMarketplaceTabId']),

    cardOpen (card) {
      if (card.url) {
        this.$router.push(this.localeLocation(card.url))
      } else {
        this.setMarketplaceTabId(card.id)
        if (this.isOutside) {
          this.$router.push(this.localeLocation('/marketplace'))
        }
      }
    },

    isActive (card) {
      if (card.url) {
        return this.$route.path.includes(card.url)
      }

      return card.id === this.marketplaceTabId && !(this.cards.filter(c => c.url).find(c => this.isActive(c)))
    }
  }
}
</script>

<template>
  <div :class="$style.categories">
    <div :class="{[$style.categories__container]: true, [$style.categories__containerOneLine]: oneLine || !isOutside }">
      <div v-for="card in cards" :key="card.id" :class="{ [$style.card]: true, [$style.cardActive]: (oneLine || !isOutside) && isActive(card) }" @click="cardOpen(card)">
        <img :src="card.icon" :class="{ [$style.icon]: true, [$style.iconActive]: (oneLine || !isOutside) && isActive(card) }" :alt="card.title" height="40" width="40" />
        <span :class="$style.text">{{ card.title }}</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.categories {
  width: 100%;

  &__container {
    @include scroll;
    background: transparent;
    display: grid;
    grid-template-columns: repeat(5, 150px);
    grid-gap: 8px;
    width: 100%;
    padding-bottom: 8px;
    overflow-x: auto;

    @media (min-width: $breakpointDesktop) {
      overflow: hidden;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 16px;
    }

    &OneLine {
      grid-template-columns: repeat(10, 150px);

      @media (min-width: $breakpointDesktop) {
        grid-template-columns: repeat(5, 1fr);
      }
    }
  }
}

.card {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 40px 1fr;
  grid-gap: 8px;
  padding: 12px;
  background: $blue-grey70;
  border-radius: 12px;
  text-align: center;
  cursor: pointer;

  &Active {
    border: 1px solid $primary50;
  }
}

.icon {
  height: 40px;
  width: 40px;
  margin: auto;

  &Active {
    * {
      fill: $primary50;
    }
  }
}

.text {
  color: $white;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
</style>
